@import '~antd/dist/antd.css';
/* @import url("https://use.typekit.net/lis8lkb.css"); */

/* @font-face {
  font-family: "Kayak";
  src: url("./assets/fonts/kayak/regular.otf");
} */

/* @font-face {
  font-family: "Kayak";
  src: url("./assets/fonts/kayak/bold.otf");
  font-weight: bold;
} */

/* @font-face {
  font-family: "Kayak";
  src: url("./assets/fonts/kayak/italic.otf");
  font-style: italic;
} */

:root {
  --primaryColor: #B1736C;
  --secondaryColor: #E9D3C5;
  --tertiaryColor: #E9D3C5;
  /* #F7EDDD; */
}

::selection {
  background-color: var(--primaryColor);
  color: white;
}

::-moz-selection {
  background-color: var(--primaryColor);
  color: white;
}

/* brandon-grotesque */
body {
  font-family: -apple-system, BlinkMacSystemFont, 
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
                'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.15ch;
  /* text-transform: uppercase; */
}

h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2 {
  /* font-family: rufina-stencil, -apple-system, BlinkMacSystemFont, 
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
                'Open Sans', 'Helvetica Neue', sans-serif; */
  color: var(--primaryColor);
}

h3.ant-typography,
.ant-typography h3,
h4.ant-typography,
.ant-typography h4 {
  color: var(--secondaryColor);
}


.ant-typography:not(h1, h2, h3, h4),
.ant-typography.ant-typography-secondary {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 500;
}

.ant-typography {
  color: var(--primaryColor);
  font-size: 12px;
}

/* .ant-typography.ant-typography-secondary {
  color: var(--primaryColor);
  font-size: 14px;
} */

.ant-typography > a,
.ant-btn-link > .ant-typography {
  background:
     linear-gradient(
       to bottom, var(--tertiaryColor) 0%,
       var(--tertiaryColor) 100%
     );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 2px 2px;
  color: var(--primaryColor);
  text-decoration: none;
  transition: background-size .2s;
}

.ant-typography > a:not(.footerLink) {
  color: var(--primaryColor) !important;
}

.ant-typography:not(h1, h2, h3, h4) > a {
  font-size: 12px;
}

.ant-btn-link:not(.subscribeButton) > .ant-typography:not(h1, h2, h3, h4) {
  font-size: 18px;
}

.ant-typography > a:hover,
.ant-btn-link:hover > .ant-typography {
  background-size: 4px 50px;
  color: var(--primaryColor) !important;
}

/* 
.ant-btn-link:hover,
.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link.active {
  border: 0;
  border-bottom: 2px solid var(--secondaryColor);
  border-radius: 0;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border: 0;
  border-bottom: 2px solid var(--secondaryColor);
  border-radius: 0;
} */

.ant-layout-content {
  background: white;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--primaryColor);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--primaryColor);
}

.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: var(--secondaryColor);
}

.ant-slider-dot-active {
  border-color: var(--primaryColor);
}

.ant-slider-handle:hover, .ant-slider-handle:focus {
  border-color: var(--primaryColor);
  -webkit-box-shadow: 0 0 0 5px rgba(177, 115, 108, 0.2);
          box-shadow: 0 0 0 5px rgba(177, 115, 108, 0.2);
}

/* .ant-slider-handle:focus {
  -webkit-box-shadow: 0 0 0 0px transparent;
          box-shadow: 0 0 0 0px transparent;
} */

.ant-slider-handle:active {
  border-color: var(--primaryColor);
}

.ant-slider .ant-slider-handle {
  border-color: var(--primaryColor);
}

.ant-slider .ant-slider-track {
  background-color: var(--primaryColor);
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--primaryColor);
}

.ant-tooltip-inner {
  text-align: center;
  color: white;
  background-color: var(--primaryColor);
  -webkit-box-shadow: 0 2px 8px var(--primaryColor);
          box-shadow: 0 2px 8px var(--primaryColor);
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  border-top-color: var(--primaryColor);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: var(--primaryColor);
}

.ant-radio-button-wrapper:not(:first-child)::before {
  left: 0;
  background-color: transparent;
}

.ant-radio-button-wrapper {
  border: 5px solid var(--primaryColor);
}

.ant-radio-button-wrapper-checked {
  /* color: white; */
  /* padding: 15px; */
  border: 5px solid var(--primaryColor);
  -webkit-box-shadow: 0 0 0 5px var(--secondaryColor);
          box-shadow: 0 0 0 5px var(--secondaryColor);
}

.ant-steps {
  /* align-self: center; */
  /* text-align: center; */
  /* margin: 0 auto; */
  /* padding: 0; */
  /* align-items: stretch; */
  padding-right: 105px;
  /* border: 5px solid black; */
  /* align-items: stretch; */
  /* overflow: hidden; */
}

.ant-steps-item {
  /* flex-basis: 0; */
  /* flex: 5; */
  width: 2vw;
  /* border: 5px solid black; */
}

.ant-radio-button-wrapper-checked::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper:last-child {
  border-radius: 0;
  border: 5px solid var(--primaryColor);
}

.ant-radio-button-wrapper-checked:first-child {
  border-left: 5px solid var(--primaryColor);
  -webkit-box-shadow: 0 0 0 5px var(--secondaryColor) !important;
          box-shadow: 0 0 0 5px var(--secondaryColor) !important;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 0px;
}

.ant-radio-button-wrapper-checked:hover {
  color: white;
  border-color: white;
  -webkit-box-shadow: 0 0 0 5px var(--secondaryColor);
          box-shadow: 0 0 0 5px var(--secondaryColor);
}

.ant-input-number-handler-wrap {
  display: none;
  border-radius: 0;
}

.ant-input-number-input {
  font-weight: bold;
  color: var(--primaryColor);
  font-size: 30px;
  text-align: center;
  border-radius: 0;
}

.ant-input-number {
  border-color: var(--primaryColor);
  /* border-radius: 0; */
}

.ant-input-number:hover {
  border-color: var(--primaryColor);
  border-right-width: 5px !important;
}

.ant-input-number:focus {
  border-color: var(--primaryColor);
  border-right-width: 5px !important;
}

.ant-input-number-focused {
  border-color: var(--primaryColor);
  border-right-width: 5px !important;
  -webkit-box-shadow: 0 0 0 0px transparent;
          box-shadow: 0 0 0 0px transparent;
}

.ant-input-affix-wrapper .ant-input-prefix {
  left: 0px;
}

.ant-input-lg {
  font-weight: bold;
  font-size: 32px !important;
  text-align: left;
  padding: 0;
}

.ant-input-lg::placeholder {
  color: var(--tertiaryColor);
}

.ant-input-lg:hover {
  border-color: white;
  /* border-right-width: 1px !important; */
}

.ant-input {
  background: none;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid var(--secondaryColor) !important;;
  text-align: left;
  color: var(--primaryColor);
  font-weight: 500;
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: transparent;
}

.ant-input:not(.ant-input.lg) {
  font-size: 18px;
}

.ant-input:focus {
  border-color: var(--secondaryColor);
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px transparent;
          box-shadow: 0 0 0 0px transparent;
}

.ant-input::placeholder {
  color: var(--secondaryColor);
}

.ant-input:hover {
  border-color: var(--secondaryColor) !important;
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: var(--secondaryColor);
}

.ant-card-head {
  border-bottom: 5px solid var(--primaryColor);
}

.ant-card-bordered {
  border: 5px solid var(--primaryColor);
}

.ant-card-actions {
  background: none;
  border-top: 5px solid var(--primaryColor);
}

.ant-menu {
  color: var(--primaryColor);
}

.ant-menu-item-selected:hover,
.ant-menu-item-selected,
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--primaryColor);
  /* border-right: 2px solid var(--primaryColor); */
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: white;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: white;
}


.ant-menu-item:hover:not(.ant-menu-item-selected),
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--primaryColor);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 5px solid var(--primaryColor);
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(var(--primaryColor)), to(var(--primaryColor)));
  background: -webkit-linear-gradient(left, var(--primaryColor), var(--primaryColor));
  background: linear-gradient(to right, var(--primaryColor), var(--primaryColor));
}

.ant-tag > .ant-typography {
  font-size: 12px;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  border: 2px solid var(--primaryColor);
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: var(--primaryColor);
}

.ant-tag-checkable-checked {
  background-color: var(--primaryColor);
}

.ant-tag-checkable-checked,
.ant-tag-checkable-checked .ant-typography
 {
  color: white;
}
.ant-tag-checkable:active {
  background-color: var(--primaryColor);
}

.ant-popover-inner-content {
  width: 300px;
}

.ant-descriptions-view {
  border-radius: 0;
}

.ant-descriptions.bordered .ant-descriptions-item-label {
  background-color: var(--primaryColor);
}

.ant-descriptions.bordered .ant-descriptions-view,
.ant-descriptions.bordered .ant-descriptions-item-label,
.ant-descriptions.bordered .ant-descriptions-item-content,
.ant-descriptions.bordered .ant-descriptions-row {
  border-color: var(--tertiaryColor);
}

.ant-descriptions.bordered .ant-descriptions-item-content {
  color: var(--primaryColor);
}

.ant-descriptions-item-label {
  color: white;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* .ant-menu.ant-menu .ant-menu-item-selected,
.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: var(--primaryColor);
} */

/* .ant-input-lg-focused {
  border-color: white;
  -webkit-box-shadow: 0 0 0 2px clear;
          box-shadow: 0 0 0 2px clear;
} */
/* .ant-radio-button-wrapper-checked:active {
  color: white;
  border-color: white;
  -webkit-box-shadow: 0px 0 0 0 white;
          box-shadow: 0px 0 0 0 white;
} */

/* .ant-radio-button-wrapper-checked:first-child {
  border-color: white;
  -webkit-box-shadow: 0px 0 0 0 white;
          box-shadow: 0px 0 0 0 white;
} */

.ant-layout-sider-zero-width-trigger {
  top: 0;
  background: transparent;
}

.ant-layout-sider-light {
  background: transparent;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: var(--primaryColor);
  background: transparent;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: var(--primaryColor);
  background: transparent;
}

.hero {
  background-image: url("./assets/img/bg-web.jpeg");
  background-size: cover;
}

.longHomeDescript {
  display: block;
}

.shortHomeDescript {
  display: none;
}

.quizBackground {
  background-image: url("./assets/img/quiz-bg-web.png");
  background-size: cover;
}

.heroImages {
  min-height: 85vh;
  width: 90vw;
  margin: 2.5vh 5vw 2.5vh 5vw;
  padding: 0;
  min-height: 0; 
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between;
}

.heroImage1, .heroImage2 {
  height: 400px;
  background-size: cover;
  background-position: center;
}

.heroImage1 {
  width: 63.5%;
}

.heroImage2 {
  width: 35%;
}

.footerBody {
  margin-top: 3px !important;
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)

  /* only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) */

  {

    .hero {
      font-weight: 700 !important;
    }

    .homeHeader {
      font-weight: 700 !important;
    }

    h1.ant-typography,
    .ant-typography h1,
    h2.ant-typography,
    .ant-typography h2 {
      /* font-family: rufina-stencil, -apple-system, BlinkMacSystemFont, 
                    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 
                    'Open Sans', 'Helvetica Neue', sans-serif; */
      font-size: 24px;
    }

    .longHomeDescript {
      display: none;
    }

    .shortHomeDescript {
      display: block;
    }

    .footerBody {
      flex-direction: column;
      align-items: center;
    }
    .navBody {
      /* flex-direction: column; */
      align-items: flex-start !important;
      /* justify-content: right !important; */
      /* justify-content: stretch !important; */
    }
    
    .navLeft {
      /* flex-direction: column !important; */
      align-items: flex-start !important;
      /* justify-content: flex-start !important; */
    }

    .navRight {
      /* flex-direction: column !important; */
      align-items: flex-end !important;
      /* float: left !important; */
    }

    .navHome {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    
    .navCenter {
      /* float: right !important; */
      display: none !important;
      /* flex-direction: column !important; */
      /* align-self: auto !important;
      justify-content: flex-start !important; */
      /* display: block !important; */
      /* float: left !important; */
      /* margin: 20px !important; */
      /* text-align: left; */
      /* margin: 0 auto !important; */

    }

    .accountBody {
      flex-direction: column !important;
    }

    .navItem {
      margin-bottom: 5px !important;
      margin-right: 7.5px !important;
    }

    /* .stepsRadioGroup {
      flex-direction: column;
      align-items: center;
    } */

    .recommendationBody {
      flex-direction: column !important;
    }

    .recommendationContainer {
      margin-right: 0 auto !important;
    }

    .checkoutBody {
      flex-direction: column-reverse !important;
    }

    .checkoutContainer {
      padding: 6vw !important;
    }

    .stepsRadioButton {
      width: 175px !important;
      height: 175px !important;
    }

    .hero {
      background-image: url("./assets/img/bg-phone.jpeg");
      background-position: bottom;
      height: 750px;
      background-size: cover;
    }

    .quizBackground {
      background-image: url("./assets/img/quiz-bg-phone.png");
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      height: 1800px;
    }

    .heroImages {
      flex-direction: column;
    }

    .heroImage1, .heroImage2 {
      width: 100%;
    }

    .hoe {
      margin-top: 500px;
      margin-left: -412px;
      max-width: 300px;
      max-height: 2000px !important;
      height: 1150px !important;
    }

    .prodcard {
      margin: 0 auto !important;
    }

    .containerNutr {
      flex-direction: column;
      align-items: center;
      height: 1800px !important;
      width: 500px !important;
    }

    .tophr {
      height: 7px !important;
    }

    .productPageBody {
      width: 120vw !important;
    }

    .midhr {
      height: 4px !important;
      margin-top: -1%;
    }

    .lihr {
      margin-left: -10% !important;
      width: 100% !important;
    }

    .holo {
      flex-direction: column !important;
      align-items: center !important;
    }

    .prodnut {
      font-size: 0.65em;
    }

    .ulnutcard {
      columns: 1 !important;
    }

    .scoop {
      font-size: 10px !important;
    }

    .a1 {
      margin-left: 5% !important;
      font-size: 0.8em !important;
      font-weight: bold !important;
    }

    .d1 {
      margin-top: -10% !important;
      margin-left: 80% !important;
      font-size: 0.8em !important;
      font-weight: bold !important;
    }

    .a2 {
      visibility: hidden;
    }

    .d2 {
      visibility: hidden;
    }

    .txtl {
      margin-left: -7% !important;
      font-size: 1em !important;
    }

    .txtr {
      margin-right: 18.5% !important;
      font-size: 1em !important;
    }

    .nutCard {
      width: 1px !important;
    }

    .prodParagraph {
      width: 42vh !important;
    }

    .swagphotos {
      margin-top: 75vh;
      margin-left: -500px !important;
      margin-right: 83px;
      background-size: 500px !important;
      width: 330px !important;
      height: 500px !important;
      background-repeat: no-repeat !important;
    }

    .prodDescription {
      margin-top: -700px !important;
      
    }

    .descUl {
      width: 275px !important;
    }

}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1)

  /* only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) */

  {
    .footerBody {
      display: inline-block !important;
      text-align: center !important;
    }

    .quizBackground {
      background-image: url("./assets/img/quiz-bg-phone.png");
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      height: 1500px;
    }

    .nutCard {
      width: 100px !important;
    }

    .hoe {
      margin-top: 500px;
      margin-left: -52vw;
      height: 650px !important;
      width: 500px !important;
    }

    .containerNutr {
      flex-direction: column;
      align-items: center;
      height: 1300px !important;
      width: 700px !important;
    }

    .tophr {
      height: 6px !important;
    }

    .midhr {
      height: 3px !important;
    }

    .lihr {
      margin-left: -10% !important;
      width: 100% !important;
    }

    .holo {
      flex-direction: column !important;
      align-items: center !important;
      width: 200px !important;
    }

    .prodnut {
      font-size: 0.65em;
    }

    .scoop {
      font-size: 10px !important;
    }

    .a1 {
      margin-left: 3% !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }

    .d1 {
      margin-top: -8% !important;
      margin-left: 34% !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }

    .a2 {
      margin-left: 52% !important;
      margin-top: -9% !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }

    .d2 {
      margin-top: -8% !important;
      margin-left: 83% !important;
      font-size: 1em !important;
      font-weight: bold !important;
    }

    .txtl {
      margin-left: -7% !important;
      font-size: 1em !important;
    }

    .txtr {
      margin-right: 18.5% !important;
      font-size: 1em !important;
    }

    .prodParagraph {
      width: 75vh !important;
    }
  }
